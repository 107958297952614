import React from 'react';
import { BANNER_SKELETON_TEST_ID } from './BannerSkeletonConstants';
import './BannerSkeleton.scss';
import SkeletonLoading from '..';

const BannerSkeleton = () => {
  return (
    <div className="home-banner-skeleton">
      <div className="home-banner-content-skeleton">
        <SkeletonLoading skeletonType="badge1" />
        <SkeletonLoading skeletonType="heading" />
        <SkeletonLoading skeletonType="subHeading" />
        <div className="home-banner-buttons-skeleton">
          <SkeletonLoading
            skeletonType={'chip'}
            testId={BANNER_SKELETON_TEST_ID.chip1Container}
          />
          <SkeletonLoading skeletonType="subHeading" />
        </div>
        <div className="home-banner-actions-skeleton">
          <SkeletonLoading
            skeletonType={'icon'}
            testId={BANNER_SKELETON_TEST_ID.chip2Container}
          />
          <SkeletonLoading
            skeletonType={'icon'}
            testId={BANNER_SKELETON_TEST_ID.chip3Container}
          />
        </div>
      </div>
      <div className="home-banner-image-skeleton">
        <SkeletonLoading
          skeletonType={'card'}
          testId={BANNER_SKELETON_TEST_ID.cardList1Container}
        />
      </div>
    </div>
  );
};

export default BannerSkeleton;