import React from 'react';
import './SkeletonLoading.scss';

const SkeletonLoading = (props) => {
  const { skeletonType, testId } = props;
  const LIST_TYPES = {
    cardList5: [1, 2, 3, 4, 5],
    cardList2: [1, 2],
    list: [1],
    badge1: [1],
    icon: [1],
    card: [1],
    chip: [1],
    chips: [1, 2, 3],
    heading: [1],
    subHeading: [1],
    chatProfile: [1, 2],
  };

  return (
    <div className={`skeleton-list ${skeletonType}`} data-testid={testId}>
      {LIST_TYPES[skeletonType].map((item) => {
        return (
          <div
            className="skeleton-item"
            key={`item_${item}`}
            data-testid={`${testId}_${item}`}
          />
        );
      })}
    </div>
  );
};

export default SkeletonLoading;
