import React from 'react';
import './Loader.scss';
import { useLoading } from '../../cache/context/LoadingProvider';

const Loader = () => {
  const { isLoading } = useLoading();

  return isLoading ? <div className="loading-screen">
    <div className="loading-spinner"></div>
  </div> : null;
};

export default Loader;