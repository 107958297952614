import { useEffect, useState } from 'react'
import './FAQs.scss'
import { getFeedback } from '../../service';

const FAQs = ({ faqRequested, goToLastState, getQuestion }) => {
    const [answer, setAnswer] = useState('');
    const [question, setQuestion] = useState('');
    const [showFeedbackSaved, setShowFeedbackSaved] = useState(false);

    useEffect(() => {
        setQuestion(faqRequested?.question)
        setAnswer(faqRequested?.answer);
    }, [faqRequested])

    const likedContent = () => {
        getFeedback(faqRequested?.questionId, true).then((res) => {
            if (res.data) {
                getQuestion();
            }
        });
        setShowFeedbackSaved(true);
    }
    const dislikedContent = () => {
        getFeedback(faqRequested?.questionId, false).then((res) => {
            if (res.data) {
                getQuestion();
            }
        });
        setShowFeedbackSaved(true);
    }

    return (
        <>
            <div className='top-wrapper'>
                <div className='container'>
                    <i onClick={() => goToLastState()} className='icon-outlined-previous-page icon-size-40'></i>
                    <p>{faqRequested?.category}</p>
                    <h3>{question}</h3></div> </div>
            <div className='content-wrapper'>
                <div className='container'>
                    <div dangerouslySetInnerHTML={{ __html: answer }}></div>  </div>
            </div>
            {showFeedbackSaved ?
                <div className='content-wrapper thanks-you'>
                    <div className='container'>
                        <a className="icon-outlined-circle-checkmark icon-medium color-green"></a>
                        <p className="font-size-24 margin-top-20">
                            <span className="color-green">Je vous remercie!</span>
                            <span> Vos commentaires ont été enregistrés. </span>
                        </p>
                    </div>   </div>
                :
                <div className='helpful-wrap'>
                    <div className='container'>
                        <div className='feedback-text'>
                            <hr />
                            <div className='row'>

                                <div className='col-sm-7'>
                                    <h3>Etes-vous satisfait ? </h3>
                                    <p>  {faqRequested?.countLikePercentage} % des clients disent que cette information était satisfaisante</p>
                                </div>
                                <div className='col-sm-5 feedback_icons'>
                                    <div className='icon-outlined-circle-helpful-up icon-medium ' onClick={() => likedContent()}></div>
                                    <div className='icon-outlined-circle-helpful-down icon-medium' onClick={() => dislikedContent()}></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}
export default FAQs;