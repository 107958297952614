export function getDistance(lat1, lon1, lat2, lon2) {
  const R = 3958.8; // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1);
  // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  // const roundedNumber = round(d);
  return d.toFixed(1);
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180)
};

export function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const filterCategories = (data, key, category) => {
  return data?.filter(ele => ele[key] === category)
}

export const filterByUniqueData = (categoryData, key) => {
  return categoryData?.filter((ele, index) => categoryData.findIndex(data => data?.[key]?.toLowerCase() === ele?.[key]?.toLowerCase()) === index)
}

export const changeImageSrcToCdn = (imgList = []) => {
  for (var i = 0; i < imgList.length; i++) {
    var newSrc = imgList[i].src.replace(/https?:\/\/[^/]+\/assets/gi, `https://cdn-webportal.airtelstream.net/website/${process.env.REACT_APP_COUNTRY}/assets`);
    imgList[i].src = newSrc;
  }
}

export const changePDFsrcToCdn = (pdfList)=>{
  for (var i = 0; i < pdfList.length; i++) {
    var newSrc = pdfList[i].href.replace(/https?:\/\/[^/]+\/assets/gi, `https://cdn-webportal.airtelstream.net/website/${process.env.REACT_APP_COUNTRY}/assets`);
    pdfList[i].href = newSrc;
  }
}