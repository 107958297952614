import * as _ from 'lodash';
import api from '../interceptor/api';


export function getAirtelStores() {
    return api.request(`cms/maps/airtelshops?opco=${process.env.REACT_APP_OPCO}`)
}

export function searchNearByStores(lat,lng,distance){
    return api.request( `cms/maps/airtelshopsbycoordinates?opco=${process.env.REACT_APP_OPCO}&longitude=${lng}&latitude=${lat}&distanceInKilometers=${distance}`)
}

export function getActivePackage(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/fetch-sites-data-5g`);
}

export function getHelp() {
    return api.request(`cms/webpage/getFaqs_pCategory?opco=${process.env.REACT_APP_OPCO}`);
}


export function getFeedback(id, value) {
    return api.request(`cms/webpage/likeCount/?questionId=${id}&isLike=${value}&opco=${process.env.REACT_APP_OPCO}`)
}

export function getMetaData(){
    return api.request(`cms/webpage/activePackage?opco=${process.env.REACT_APP_OPCO}&pageUrl=/meta-data-config`)
}

