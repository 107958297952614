import ReactGA from "react-ga4";
import React, { useEffect} from 'react';

export const ga4Initialize = () => {
  let trackingId = "";
  if (trackingId)
    ReactGA.initialize(trackingId);
}
export const sendGA4PageView = () => {
  ReactGA.send({
     hitType: "pageview",
     page: window?.location?.pathname,
     title: window?.document?.title
    
    });
}
 
export const sendGA4Event = ({ category, action, label, value }) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    value: value, // optional, must be a number
  });
}

