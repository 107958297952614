export const LANDING_SKELETON_TEST_ID = {
  icon1Container: 'icon1Container',
  icon2Container: 'icon2Container',
  icon3Container: 'icon3Container',
  subHeading1Container: 'subHeading1Container',
  subHeading2Container: 'subHeading2Container',
  subHeading3Container: 'subHeading3Container',
  subHeading4Container: 'subHeading4Container',
  subHeading5Container: 'subHeading5Container',
  subHeading6Container: 'subHeading6Container',
  heading1Container: 'heading1Container',
  heading2Container: 'heading2Container',
  heading3Container: 'heading3Container',
  heading4Container: 'heading4Container',
};
