import React, { Suspense ,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes ,useNavigate} from 'react-router-dom';
import NetworkCoverage from '../../Shared/Components/NetworkCoverage/NetworkCoverage';
import StoreLocator from '../../Shared/Components/StoreLocator/StoreLocator';
import GetHelp from '../../Shared/Components/GetHelp/GetHelp';
import LandingSkeleton from '../../Shared/Components/SkeletonLoader/LandingSkeleton';
import { LoadingProvider } from '../../Shared/cache/context/LoadingProvider';
import { ExternalLink } from '../../Shared/Components/ExternalLink';
import { ga4Initialize, sendGA4PageView } from '../../Shared/helper/google-analytics';
const HomePageComponent = React.lazy(() => import('../pages/HomePageComponent'));
const StaticPageComponent = React.lazy(() => import('../pages/staticPage'));
const PageNotFondComponent = React.lazy(() => import('../pages/pageNotFound'));
const LandingPageLayout = React.lazy(() => import("../layouts/LandingPageLayout"));


const AppRoutes = () => {

  useEffect(() => {
    sendGA4PageView();
    ga4Initialize();
  }, [window.location.pathname]);


  return (
    <Router>
      <LoadingProvider>
        <Suspense fallback={window.location.pathname === "/" ? <LandingSkeleton /> : <div></div>}>
          <LandingPageLayout>
            <Routes>
              <Route path="/" element={<HomePageComponent />} />
              <Route path="/about" element={<StaticPageComponent />} />
              <Route path="/page-not-found" element={<PageNotFondComponent />} />
              <Route path="/storeLocator" element={<StoreLocator />} />
              <Route path="/openNetworks" element={<NetworkCoverage />} />
              <Route path="/gethelp" element={<GetHelp />} />
              {/* <Route path="/prepaid-recharge"element={<ExternalLink to="prepaid-recharge"/>} />
              <Route path="/postpaid-bill-pay"element={<ExternalLink to="postpaid-bill-pay"/>} />
              <Route path="/broadband-recharge"element={<ExternalLink to="broadband-recharge"/>} />
              <Route path="/data-bundle"element={<ExternalLink to="data-bundle"/>} /> */}
              <Route path="*" element={<StaticPageComponent />} />
            </Routes>
          </LandingPageLayout>
        </Suspense>
      </LoadingProvider>
    </Router>
  )
}

export default AppRoutes;