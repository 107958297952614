import React from 'react';
import { LANDING_SKELETON_TEST_ID } from './LandingSkeletonConstants';
import './LandingSkeleton.scss';
import SkeletonLoading from '..';
import BannerSkeleton from '../BannerSkeleton';

const LandingSkeleton = () => {
  return (
    <div className="landing-loading-container" data-testid="landing-skeleton">
      {/* {!getFromSessionStorage(STORAGE_CONSTANTS.ANNOUNCEMENT_BAR_SHOWN) && (
            <div className="announcement-bar-skeleton"></div>                          // Commented for Future Use
         )} */}
      <BannerSkeleton />
      <div className="home-recharges-skeleton">
        <SkeletonLoading
          skeletonType="heading"
          testId={LANDING_SKELETON_TEST_ID.heading1Container}
        />
        <SkeletonLoading
          skeletonType="chips"
          testId={LANDING_SKELETON_TEST_ID.heading2Container}
        />
        <SkeletonLoading
          skeletonType="heading"
          testId={LANDING_SKELETON_TEST_ID.heading3Container}
        />
        <SkeletonLoading
          skeletonType="heading"
          testId={LANDING_SKELETON_TEST_ID.heading4Container}
        />
      </div>
    </div>
  );
};

export default LandingSkeleton;